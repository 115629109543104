import React, { Component } from "react"
import { Link } from "gatsby"
import './../assets/scss/index.scss'
import ContactFooter from '../components/contactfooter'
import ScroolTop from '../components/scrolltop'
import { removePre } from './../util/common';
import Slider from "react-slick";
import SEO from "../components/seo"
import './../../node_modules/aos/dist/aos.css'; 
import AOS from 'aos'; 
import Typed from 'react-typed';


class Home extends Component {
  constructor(props, context) { 
    super(props, context); 
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  } 
  componentDidMount() {
    const isBrowser = typeof document !== "undefined";
    const AOS = isBrowser ? require("aos") : undefined;

    this.aos = AOS;
    this.aos.init();
    
}
  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  
  render() {
    const data = this.props.data;    
    const banner = data.wordpressPage.acf;
    const work = data.allWordpressWpWork.edges;
    const serviceslist = data.wordpressPage.acf.service_section.service_sec_featured_list;
    const testimonials = data.allWordpressWpTestimonial.edges;
    const getintouch = data.allWordpressMenusMenusItems.nodes[0].items[6];
    const services = data.allWordpressMenusMenusItems.nodes[0].items[2];
    
    //var oldString = 'this, is my, string';
    var oldString = banner.banner_blink_word;
    var mynewarray=oldString.split(',')
    console.log("My New Array Output is",mynewarray);

    var worksettings = {      
      className: "center",
      infinite: true,
      centerPadding: "0",
      speed: 4000,
      swipeToSlide: true,
      variableWidth: true,
      
    };
    var testisettings = {
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: true,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '250px',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: '0px',
            slidesToShow: 1
          }
        }
      ]
    }
    var responsivetestisettings = {
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: true,
      slidesToShow: 1,
    } 
    return (
      <>
        <SEO title="Home" />        
        <div className="page-header home">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">                      
                      <div className="banner-typing-content">                       
                        <h1>{banner.banner_content}{" "} 
                          <Typed strings={mynewarray} 
                          typeSpeed={120} 
                          backSpeed={50}  
                          cursorChar= {'_'} 
                          loop />                            
                           
                        </h1>
                      </div>                      
                  </div>
              </div>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 slider-content">
                <h2>Experience design and development for growing brands.</h2>
                <Link to={`${removePre(getintouch.url)}`} className="slider-pro-btn">Got a new project?<span>Contact us</span></Link>
                <div className="slider-custom-nav">
                  <div className="custom-nav prev">  
                    <button className="button" onClick={this.previous}>
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                  </button> 
                  </div>
                  <div className="custom-nav-bottom">
                    <span className="dots"></span>
                    <span className="dots"></span>
                  </div>
                  <div className="custom-nav next">
                  <button className="button" onClick={this.next}>
                  <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </button>                      
                  </div>
                </div>
              </div>
              <div className="col-md-8 slider-images work-slider">
              <Slider ref={c => (this.slider = c)} {...worksettings}>
                {work.map((node, index) => (
                  <div key={index}>
                    {node.node.featured_media !== null &&
                      <Link to={node.node.path}><img src={node.node.featured_media.source_url} alt={node.node.title}/></Link>
                    }                    
                  </div>
                ))} 
              </Slider>
              <div className="slider-custom-nav">
                  <div className="custom-nav prev">  
                    <button className="button" onClick={this.previous}>
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                  </button> 
                  </div>
                  <div className="custom-nav-bottom">
                    <span className="dots"></span>
                    <span className="dots"></span>
                  </div>
                  <div className="custom-nav next">
                  <button className="button" onClick={this.next}>
                  <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </button>                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-services">
          <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <h3 className="service-sec-title"><Link to={`${removePre(services.url)}`}>Services</Link></h3>
                <div className="services-list row">
                  {serviceslist.map((node, index) => (
                     <div className={index > 2 ? 'col-md-4 hide-service' : 'col-md-4 show-service'} key={index}>
                       <div className="title">
                           <h4><Link to={'/services/#'+node.repeater_service_id} className="servicelinks">{node.repeater_service_title}</Link></h4>
                        </div>
                        <ul>
                        {node.repeater_service_list.map((node, index) => (
                            <li key={index}>{node.service_fea_list}</li>
                        ))}
                        </ul>                        
                     </div>
                  ))}
                </div>
            </div>
            </div>
          </div>          
        </div>
        <div className="testimonial-slider testi-desktop">          
          <div className="testimonial">
            <Slider {...testisettings}>
              {testimonials.map((node, index) => (
                <div key={index}>
                  <h4>{node.node.acf.testimonial_author_comment}</h4>
                  <div className="testi-author">{node.node.title}</div>
                </div>
              ))}  
            </Slider>
          </div>
        </div>
        <div className="testimonial-slider testi-mobile">          
          <div className="testimonial">
            <Slider {...responsivetestisettings}>
              {testimonials.map((node, index) => (
                <div key={index}>
                  <h4>{node.node.acf.testimonial_author_comment}</h4>
                  <div className="testi-author">{node.node.title}</div>
                </div>
              ))}  
            </Slider>
          </div>
        </div>
        <div className="client-logo-section">
          <div className="container">
            <div className="logo-heading">                
                <h1>{banner.technology_section.tech_sec_section_title}</h1>
              </div>
            <div className="c-logos">
              <div className="row">
                {banner.technology_section.tech_sec_logos_repeater.map((node,index) => (
                  <div className="col-md-3 col-sm-6" key={index}>
                    <div className="image-holder">
                      <img src={node.tech_sec_logos.source_url} alt="Technology"/>
                    </div>
                  </div>
                ))}                
              </div>
            </div>
          </div>
        </div>
        <ContactFooter />
        <ScroolTop />
      </>
    )}}

export default Home

export const query = graphql`
{
  allWordpressMenusMenusItems {
    nodes {
        name
        items {
        title
        url
        }
    }
}
allWordpressWpWork(filter: {acf: {work_show_on_home_page: {eq: true}}}, limit: 5) {
  edges {
    node {
      wordpress_id
      path
      featured_media {
        source_url
      }
      acf {
        work_show_on_home_page
      }
    }
  }
}
  allWordpressWpService {
    edges {
      node {
        slug
        title
      }
    }
  }
  allWordpressWpTestimonial {
    edges {
      node {
        title
        acf {
          testimonial_author_comment
          testimonial_site_url
        }
      }
    }
  }
  wordpressPage(wordpress_id: {eq: 7}) {
    acf {
        banner_heading
        banner_content
        banner_blink_word
        service_section {
          service_sec_section_link
          service_sec_featured_list {
            repeater_service_id
            repeater_service_title
            repeater_service_list {
              service_fea_list
            }
          }
        }
        technology_section {
          tech_sec_heading
          tech_sec_section_title
          tech_sec_logos_repeater {
            tech_sec_logos {
              source_url
            }
          }            
        }
    }
  }  
}
`
